// /src/duty.js

import React from 'react';

/**
 * These are root pages
 */

const dutyContent = () => {
    return <div> 
        <div class="submenu">報稅說明</div>
        
        <div class="btn_class"><a href="#duty01">什麼是金融憑證？</a><a href="#duty02">使用金融憑證報稅的好處？</a><a href="#duty03">金融憑證使用說明？</a></div>
            
        <div class="section">
            <div class="title1"><a name="duty01" id="duty01"></a>什麼是金融憑證？</div>
        <p class="indent">電子報稅軟體具備多種不同的登入方式，電子憑證就包含了兩種，分別是「自然人憑證」與「金融憑證」：
            <ul>
                <li><span class="blue">「自然人憑證」</span>：是由內政部核發由自然人向各地區戶政事務所申辦核發之憑證，為目前廣為所知的電子憑證之一。</li>
                <li><span class="blue">「金融憑證」</span>：是由經過財政部逐年審核通過的憑證簽發機構透過金融機構單位所發出之電子憑證，除用於各金融機構（銀行、證券商、保險公司、金控公司等）所屬之證劵下單、網路銀行及網路保險等業務應用外，也可申請加入網路報稅憑證。</li>
            </ul>
        </p>
        <p class="indent">中華電信股份有限公司依據電子簽章法第十一條及經濟部頒訂之「憑證實務作業基準應載明事項準則」之規定，制定中華電信通用憑證管理中心，並於經濟部核定公佈於網站後，開始提供簽發憑證服務。</p>
            <p class="gray_line">所簽發之憑證適用於開放式網路環境，針對資訊的傳遞提供加密及身份識別之用，使用業務範圍包括網路銀行、網路報稅、電子發票等金融相關交易。</p>
        </div>
        
        <div class="section">
            <div class="title1"><a name="duty02" id="duty02"></a>使用金融憑證報稅的好處？</div>
        <p class="indent">使用網路報稅不一定需要跑到戶政單位申請自然人憑證，如果您有金融交易或證劵下單等憑證，一樣也可以輕鬆完成網路報稅/繳稅程序。比起自然人憑證，不僅可以<span class="blue">省去親辦申請的流程與時間，取得成本也低，也不用擔心憑證遺失重新補發</span>(可線上重新下載安裝)，<span class="blue">更免去讀卡機插卡的問題</span>，實是一舉數得。</p>
            <p class="indent">金融(證劵)憑證與自然人憑證一樣是用來識別交易者的身分，不同的是金融(證劵)憑證是由金融單位所簽發，只需你在銀行/證券/保險等金融機構單位開戶的時候，註明通知對方你需要開通「<span class="blue">網路下單」</span>即可取得金融(證劵)憑證，通常其有效期限為 1 年，但在有效期限內也可申請展期，不但不限次數也不需額外的手續及工本費用。</p>
            <p class="indent">本國人使用「證券下單憑證」最大的好處就是能簡化報稅程序，只要安裝並執行 { (new Date().getFullYear()) - 1911 } 年度綜合所得稅電子結算申報軟體，選擇採用「使用金融憑證登入」，輸入憑證密碼就可進入電子結算申報系統，然後系統會自動下載報稅相關資料；資料包含： { (new Date().getFullYear()) - 1911 } 年度「本人、配偶、未成年子女」、「前一年度被納稅義務人或其配偶列報扶養之已成年在學子女與「前兩個年度起連續被納稅義務人或其配偶列報扶養之直系尊親屬」之戶籍資料、各類所得扣繳暨免扣繳憑單(包括執行業務所得，但不包含執行業務事務所轉開予合夥人之扣繳憑單)、股利憑單、緩課股票轉讓所得申報憑單、信託財產各類所得憑單、信託證券交易所得憑單、信託財產緩課股票轉讓所得申報憑單及個人計程車營利所得等所得資料，以及捐贈、保險費、醫藥及生育費、災害損失、購屋借款利息、身心障礙、教育學費等扣除額資料。</p>
            <p class="indent">此時納稅義務人只需對於 { (new Date().getFullYear()) - 1911 } 年度實際應申報之基本資料及個人所得資料及各項扣除額等項目，逐筆確認、新增或修正無誤，由電腦自動試算稅額後，回傳申報資料，上傳成功後建議列印收執聯妥善保存，另將應檢附證明文件寄送至戶籍所在地國稅局所屬分局、稽徵所、服務處，或於6月13日前就近送至任一國稅局所屬分局、稽徵所及服務處代收，即完成報稅手續了。</p>
            <p class="gray_line">外僑使用「證券下單憑證」最大的好處就是能簡化報稅程序，<span class="blue">只要安裝並執行 { (new Date().getFullYear()) - 1911 } 年年度外僑綜合所得稅電子結算申報軟體，選擇採用「使用金融憑證登入」，輸入憑證密碼就可進入電子結算申報系統</span>，然後系統會自動下載報稅相關資料；資料包含： { (new Date().getFullYear()) - 1911 } 年度納稅義務人的基本資料、入出境、個人所得及扣除額等資料。此時納稅義務人只需對於 { (new Date().getFullYear()) - 1911 } 年度實際應申報之項目，逐筆確認、新增或修正無誤，由電腦自動試算稅額後，回傳申報資料，上傳成功後，建議列印收執聯妥善保存，另將應檢附證明文件親送或寄送至（依居留證所載）所在地的國稅局（臺北、高雄國稅局為總局服務科外僑股；北、中、南區國稅局為所屬分局、稽徵所、服務處），即完成報稅手續了。</p>
        </div>
        
        <div class="section">
            <div class="title1"><a name="duty03" id="duty03"></a>金融憑證使用說明？</div>
        <ul>
            <li>報稅軟體下載：<br />
            「綜合所得稅電子結算申報繳稅系統」財政部電子申報繳稅服務網站<br />
            <a href="http://tax.nat.gov.tw/declist.html">http://tax.nat.gov.tw/declist.html</a></li>
            <li>操作手冊下載：<br />
                財政部電子申報繳稅服務網站<br />
            <a href="http://tax.nat.gov.tw/info_IRX_user.html?id=1">http://tax.nat.gov.tw/info_IRX_user.html?id=1 </a></li>
        </ul>
        <table border="0" cellspacing="1" cellpadding="8" class="from">
            <tr>
                <th>&nbsp;</th>
            <th width="120">金融憑證</th>
            <th>憑證作業說明</th>
            </tr>
            <tr>
                <td>一</td>
            <td>財政部逐年審核電子報稅憑證─金融憑證</td>
            <td>各金融機構（銀行、證券商、保險公司、金控公司等）所屬之網路下單、網路銀行及網路保險等憑證，可申請加入網路報稅憑證，但憑證簽發機構需逐年提出申請並通過財政部審核，當年度審核通過之金融憑證可適用於5月份網路查調所得及報稅。</td>
            </tr>
            <tr>
                <td>二</td>
            <td align="center">憑證申請</td>
            <td>可逕向金融憑證所屬各銀行、證券商、保險公司、金控公司申辦及洽詢相關下載憑證檔案作業流程。</td>
            </tr>
            <tr>
                <td rowspan="2">三</td>
            <td rowspan="2" align="center">憑證功能</td>
            <td>1.平時使用：金融憑證除了平日使用於各金融機構網路下單、網路銀行及網路保險等平日之私人金融交易外，尚可於綜合所得稅</td>
            </tr>
            <tr>
            <td>2.報稅使用：下載安裝當年度綜合所得稅電子結算申報繳稅系統（每年約4月中旬提供下載），於每年5月份所得稅申報期 間，利用報繳稅系統，選擇使用金融憑證登入，透過網路查詢下載所得資料參考，並就當年度實際應申報之所得、扣除額及稅籍資料作調整增修後再上傳完成申報，節省親自到國稅局臨櫃查詢所得時間。</td>
            </tr>
            <tr>
            <td>四</td>
            <td>本年度審核通過之憑證單位</td>
            <td>本年度財政部審核通過可用於 { (new Date().getFullYear()) - 1911 } 年度個人綜合所得稅網路申報之電子金融憑證發放單位共30家（使用於 { (new Date().getFullYear()) - 1911 } 年5月1日至 { (new Date().getFullYear()) - 1911 } 年5月31日），包括：
                <ol>
                    <li>銀行：合作金庫商業銀行、兆豐國際商業銀行、台灣土地銀行、彰化商業銀行、台灣中小企業銀行。</li>
                    <li>證券商：元大證券、合作金庫證券、元富證券、日盛證券、永豐金證券、玉山綜合證券、宏遠證券、亞東證券、國泰綜合證券、國票綜合證券、第一金證券、統一綜合證券、凱基證券、富邦綜合證券、華南永昌綜合證券、群益金鼎證券、臺銀綜合證券、兆豐證券、台新綜合證券、中國信託證券、大昌證券、新光證券。</li>
                    <li>人壽保險公司：南山人壽保險（股）公司。</li>
                </ol>
            </td>
            </tr>
            <tr>
            <td>五</td>
            <td align="center">憑證使用</td>
            <td>無須搭配讀卡機</td>
            </tr>
            <tr>
                <td>六</td>
            <td align="center">憑證期限</td>
            <td>各金融憑證所屬金融機構規定期限，一般原則為1年，到期可展延。</td>
            </tr>
        </table>
        </div>
        
        <div align="right"><a href="#top"><span class="btn_blue">回頂端</span></a></div>
        
    </div>;
};


export { dutyContent};