import React from 'react'
import { Link } from 'react-router-dom';

class SubMenu extends React.Component {
    render() {
        return (
            <div class="submenu">
                <Link to="/">中國信託</Link> ‧
                <Link to="/result2">元大證券</Link> ‧
                <Link to="/result3">SSL憑證應用</Link> ‧
            </div>
        );
    }
}

export default SubMenu


