
import React from 'react';

import intro_illus_02_1 from "../img/intro_illus_02-1.png"
import intro_illus_02_2 from "../img/intro_illus_02-2.png"
import intro_illus_02_3 from "../img/intro_illus_02-3.png"

const product01 = () => {
    return <div>
            <div class="submenu">證券下單憑證</div>
      
            <div class="gray_section">覺得設立CA的法定程序很麻煩嗎?<br />覺得維運CA伺服器很耗費成本嗎?<br />中華電信「Public CA」提供的便利性與安全性，將是您在佈局公開金鑰基礎建設時最好的選擇。</div>
        
            <div class="section">
                <div class="title1">產品簡介：</div>
                <p class="gray_line">CA為佈局公開金鑰基礎建設時不可或缺的一員，但又覺得設立一個CA很麻煩嗎? 現在中華電信「Public CA」提供您現成的CA，「Public CA」已向經濟部申請設立通過，系統維運也已通過 ISO 27001認證並符合WebTrust for CA等級的認證。您不必自己建置及維運CA系統，只要提供您的需求，我們即可迅速建立您的專屬RA群組，使用起來就猶如您自己有一套專屬的CA系統，並可在短時間內提供憑證測試環境供您實際操作，絕對是您在佈局公開金鑰基礎建設時最好的選擇。</p>
            </div>
        
            <div class="section">
                <div class="title1">應用架構：</div>
                <p class="gray_line"><img src={intro_illus_02_1} /></p>
            </div>
        
            <div class="section">
                <div class="title1">產品規格：</div>
                <ul class="gray_line">
                <li>產品符合 RFC 3280及ITU-T X.509 Fifth Edition(2005)規範
                    <ul>
                    <li>憑證格式<span class="orange"> - X.509 V3</span></li>
                    <li>憑證廢止清冊(CRL)格式<span class="orange"> - X.509 V2</span></li>
                    </ul>
                </li>
                <li>支援Token種類
                    <ul>
                    <li>我國政府PKI各類IC卡</li>
                    <li>中華電信商用PKI IC卡</li>
                    </ul>
                </li>
                <li>軟體金鑰格式
                    <ul>
                    <li>PKCS#12</li>
                    </ul>
                </li>
                <li>核發憑證種類
                    <ul>
                    <li>Business to Customer (B2C) 個人憑證</li>
                    <li>Business to Business (B2B) 法人與組織團體憑證</li>
                    <li>SSL伺服器憑證</li>
                    </ul>
                </li>
                </ul>
            </div>
        
            <div class="section">
                <div class="title1">產品特色：</div>
                <ul class="gray_line">
                <li>接受客製化作業，更符合您的需求
                    <ul class="nodisc">
                    <li>使用中華電信Public CA，我們可以依照您的需求來設計憑證內存放欄位，讓您可以與原有系統快速整合，增加方便性。</li>
                    </ul>
                </li>
                <li>自行發卡印卡，提高擴充與便利性
                    <ul class="nodisc">
                    <li>客戶可以自行核發、廢止憑證，在人事流動率高的現代可以節省許多寶貴的時間。</li>
                    </ul>
                </li>
                <li>安全、穩定性高
                    <ul class="nodisc">
                    <li>Public  CA穩定性高，系統維運也已通過  ISO 27001認證並符合WebTrust for CA等級的認證，客戶可完全放心。</li>
                    </ul>
                </li>
                <li>專屬的註冊中心管理介面
                    <ul class="nodisc">
                    <li>為客戶製作一個屬於自己的管理介面，完全客制化。管理介面顯示欄位即為客戶所需欄位，不需再填寫一些不必要的欄位。</li>
                    </ul>
                </li>
                <li>快速提供憑證測試環境
                    <ul class="nodisc">
                    <li>我們可以快速提供客戶憑證測試環境，讓客戶充分操作進而了解實際使用情形，評估是否符合需求。客戶並可依實際操作過後心得與我們協調出最適合的產品。</li>
                    </ul>
                </li>
                </ul>
            </div>
        
            <div class="section">
                <div class="title1">產品功能：</div>
                <ul class="gray_line">
                <li>核發憑證
                    <ul class="nodisc">
                    <li>提供對個人及機關團體簽發憑證的服務。</li>
                    </ul>
                </li>
                <li>註銷憑證
                    <ul class="nodisc">
                    <li>提供註銷過去簽發之憑證的服務。</li>
                    </ul>
                </li>
                <li>查詢憑證效期
                    <ul class="nodisc">
                    <li>提供查詢憑證有效期限的服務。</li>
                    </ul>
                </li>
                <li>解鎖
                    <ul class="nodisc">
                    <li>當卡片/HiKey使用者鍵入錯誤的PIN碼次數達到一個定量造成卡片被鎖的情況時，提供RAO利用RAO卡來幫使用者解鎖的機制，以確保解鎖卡的資訊安全。</li>
                    </ul>
                </li>
                <li>紀錄憑證核發流程
                    <ul class="nodisc">
                    <li>留存憑證核發紀錄，以便日後查核。</li>
                    </ul>
                </li>
                </ul>
            </div>
        
            <div class="section">
                <div class="title1">應用情境：</div>
                <ul class="gray_line">
                <li>網路下單<img src={intro_illus_02_2}/></li>  
                <li>郵件加密<img src={intro_illus_02_3} /></li>
                </ul>
            </div>
        
            <div class="section">
                <div class="title1">得獎記錄：</div>
                <ul class="gray_line">
                <li>八十九年全國資訊月傑出資訊應用暨產品獎─電子化政府類</li>
                <li>九十一年全國資訊月傑出資訊應用暨產品獎─電子化政府類</li>
                </ul>
            </div>
        
            <div class="section">
                <div class="title1">本公司另提供選購產品API套件規格如下：</div>
                <ul class="gray_line">
                <li>憑證工具
                    <ul>
                    <li>X.509V3憑證格式欄位完整解譯，可讀取憑證中資訊</li>
                    </ul>
                </li>
                <li>數位簽章驗證
                    <ul>
                    <li>驗證簽章本文與簽體是否相符</li>
                    <li>驗證憑證本體簽章及其效期</li>
                    <li>以憑證廢止清冊 (CRL) 驗證憑證有效性</li>
                    <li>採用簽章過的ActiveX元件，可經由IE進行自動安裝，用戶端無須進行安裝動作</li>
                    </ul>
                </li>
                <li>憑證 IC 卡登入驗證
                    <ul>
                    <li>登入驗證採用Challenge and Response機制，可防止重送攻擊(Replay Attack)</li>
                    <li>憑證完整性及有效性驗證</li>
                    <li>可設定排程下載憑證廢止清冊 (CRL)</li>
                    </ul>
                </li>
                <li>數位信封及數位簽章
                    <ul>
                    <li>可產製符合PKCS#7數位信封及數位簽章格式</li>
                    </ul>
                </li>
                </ul>
            </div>
        
            <div class="section">
                <div class="title1">PKI應用領域：</div>
                <ul class="gray_line">
                <li>企業PKI員工卡</li>
                <li>校園PKI教職員學生卡</li>
                <li>安全SSL網頁伺服器 </li>
                <li>網路電子投保</li>
                <li>網路電子下單</li>
                </ul>
            </div>
        
            <div class="section">
                <span class="title1">產品網頁：</span><a href="http://publicca.hinet.net">http://publicca.hinet.net</a>
            </div>
        
            <div align="right"><a href="javascript:history.go(-1);"><span class="btn_blue">返回</span></a></div>     
        </div>
};

export { product01};