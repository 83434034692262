import React from 'react';

import product_09 from "../img/product_09.png"

const product05 = () => {
    return <div>  
        <div class="submenu">中華電信隨身讀卡機(CHT BT-100)</div>
        
        <div style= {{paddingTop:'30px', float:'left'}} ><img src = { product_09 } /></div>
        <div class="gray_section" style={{width:'55%', marginBottom:'30px', float:'right'}}>CHT BT-100是一款支援藍牙2.0無線傳輸標準的IC卡讀卡機，只要您的手機或平板電腦為Android 2.3以上、iOS4.0以上的版本以及支援Windows XP作業系統，透過藍牙讀卡機，就可以輕鬆地無線讀寫您手邊的IC卡片，免除接線的煩惱。擁有一台 CHT BT-100，讓您隨時隨地解決所有事情。</div>
        <div class="clear"></div>
        
        <div class="section">
            <div class="title1">產品特色：</div>
            <ul class="gray_line">
            <li>符合藍牙 2.0 的傳輸規格</li>
            <li>LED 指示燈顯示電源及插卡狀態或資料傳輸</li>
            <li>支援自然人憑證</li>
            </ul>
        </div>
        
        <div class="section">
            <div class="title1">系統需求：</div>
            <ul class="gray_line">
            <li>Android 2.3 以上版本、iOS4.0以上的版本以及支援Windows XP以上版本作業系統具備藍牙通訊的行動裝置，包含手機及平板電腦</li>
            </ul>
        </div>
        
        <div class="section">
            <div class="title1">智慧卡支援：</div>
            <ul class="gray_line">
            <li>支援符合ISO-7816 T=0,T=1標準規格之接觸式IC智慧卡，如：自然人憑證、ATM金融卡、醫事人員卡</li>
            </ul>
        </div>
        
        <div class="section">
            <div class="title1">介面：</div>
            <ul class="gray_line">
            <li>符合藍牙2.0的傳輸規格</li>
            </ul>
        </div>
        
        <div class="section">
            <div class="title1">LED 指示燈：</div>
            <ul class="gray_line">
            <li>電源開啟後，綠燈慢速閃爍為可連線狀態</li>
            <li>電源開啟後，綠燈快速閃爍為目前正在與行動裝置通訊中狀態</li>
            <li>電源開啟後，紅燈閃爍時為電力即將不足狀態</li>
            <li>插入USB線時，紅色燈號為充電中，燈號熄滅為充電完成</li>
            </ul>
        </div>
        
        <div class="section">
            <div class="title1">電源：</div>
            <ul>
            <li>電池容量320 mAh (可連續待機60小時)，支援micro USB插槽充電</li>
            </ul>
        </div>
        
        <div align="right"><a href="javascript:history.go(-1);"><span class="btn_blue">返回</span></a></div>
      
    </div>
};



export { product05 };