import React from 'react'
import { Link } from 'react-router-dom';

class Header extends React.Component {
    render() {
      return (
        <div className="header" >
            <div className="toplink_l">
                <Link to="/">
                    <span className="btn_blue">回首頁</span>
                </Link>
            </div>
            <div className="toplink_r">
                <Link to="/product/product_list">
                    <span class="btn_blue">業務諮詢</span>
                </Link>
            </div>
        </div> 
    );
    }
}

export default Header


