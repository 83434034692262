// /src/subMenuContent.js

import React from 'react';
import SubMenu from '../components/SubMenu';

/**
 * These are root pages
 */

const indexContent = () => {
    return ;
};



const ctbc = () => {
  return <div>
            <SubMenu /> 
            <div class="title1">中國信託使用中華電信憑證服務</div>
            中國信託商業股份有限公司自民國九十八年起，向中華電信採購企業金融電子憑證(B2B憑證)與註冊中心憑證(RA憑證)，應用於各項法人金融非約定轉帳交易（自行)，憑證內容與憑證欄位遵循銀行公會所制訂的金融XML憑證共通性技術規範，私密金鑰載具為中國信託所選訂之USB Token。;
        </div>
};

const yuanta = () => {
  return <div>
            <SubMenu /> 
            <div class="title1">元大證券使用中華電信憑證服務</div>
            元大證券與中華電信合作發放證券下單憑證，由元大證券於各地證券櫃臺辦理用戶憑證註冊審驗，由中華電信通用憑證管理中心簽發2048位元之下單憑證，元大證券之用戶自103年起並可使用證券下單憑證於綜合所得稅網路報稅。;
        </div>
};

const SSL = () => {
    return <div> 
                <SubMenu /> 
                <div class="title1">其它金融業SSL憑證應用：</div>
                <ol>
                <li>台灣銀行學雜費入口網 <a href="https://school.bot.com.tw">https://school.bot.com.tw</a></li>
                <li>台灣銀行信用卡系統  <a href="https://ecard.bot.com.tw">https://ecard.bot.com.tw</a></li>
                <li>臺灣銀行 e 企合成網 <a href="https://necomb.bot.com.tw">https://necomb.bot.com.tw</a></li>
                <li>兆豐商銀數位學習園地  <a href="https://learning.megabank.com.tw">https://learning.megabank.com.tw</a></li>
                <li>台北金融大樓股份有限公司網頁郵件系統<br />
                    <a href="https://webmail.tfc101.com.tw">https://webmail.tfc101.com.tw</a><br />
                    <a href="https://autodiscover.tfc101.com.tw">https://autodiscover.tfc101.com.tw</a><br />
                    <a href="https://mail.tfc101.com.tw">https://mail.tfc101.com.tw</a><br />
                </li>
                <li>財團法人金融消費評議中心 <a href="https://www.foi.org.tw">https://www.foi.org.tw</a></li>
                <li>金融消費評議中心申訴暨評議案件管理系統 <a href="https://fao.foi.org.tw">https://fao.foi.org.tw</a></li>
                <li>南山人壽保險股份有限公司 SSL VPN<br />
                    <a href="https://seavpn.nanshan.com.tw">https://seavpn.nanshan.com.tw</a><br />
                    <a href="https://seaowa.nanshan.com.tw">https://seaowa.nanshan.com.tw</a><br />
                </li>
                </ol>
            </div>
};

/**
 * These are pages nested in Electronics
 */
const faq01 = () => {
    return <div>
        <div class="submenu">常見問題區</div>
        <div class="title1">1. 什麼是金融憑證？</div>
        <p><span class="orange"><b>A：</b></span>金融(證劵)憑證與自然人憑證一樣是用來識別交易者的身分，不同的是金融(證劵)憑證是由金融單位所簽發，只需你在銀行/證券/保險等金融機構單位開戶的時候，註明通知對方你需要開通「網路下單」即可取得金融(證劵)憑證，通常其有效期限為 1 年，但在有效期限內也可申請展期，不但不限次數也不需額外的手續及工本費用。</p>
        <div align="right"><a href="javascript:history.go(-1);"><span class="btn_blue">返回</span></a></div>
    </div>;
};

const faq02 = () => {
    return <div>
        <div class="submenu">常見問題區</div>
        <div class="title1">2. 我有金融憑證可以作什麼？</div>
        <p><span class="orange"><b>A：</b></span>「金融憑證」是由經過財政部逐年審核通過的憑證簽發機構透過金融機構單位所發出之電子憑證，除用於各金融機構（銀行、證券商、保險公司、金控公司等）所屬之證劵下單、網路銀行及網路保險等業務應用外，也可申請加入網路報稅憑證。</p>
        <div align="right"><a href="javascript:history.go(-1);"><span class="btn_blue">返回</span></a></div>
    </div>;
};

const faq03 = () => {
    return <div>
        <div class="submenu">常見問題區</div>
        <div class="title1">3. 使用金融憑證報稅的好處？</div>
        <p><span class="orange"><b>A：</b></span>本國人使用「證券下單憑證」最大的好處就是能簡化報稅程序，只要安裝並執行該年度綜合所得稅電子結算申報軟體，選擇採用「使用金融憑證登入」，輸入憑證密碼就可進入電子結算申報系統，然後系統會自動下載報稅相關資料。</p>
        <p>納稅義務人只需對於該年度實際應申報之基本資料及個人所得資料及各項扣除額等項目，逐筆確認、新增或修正無誤，由電腦自動試算稅額後，回傳申報資料，上傳成功後建議列印收執聯妥善保存，另將應檢附證明文件寄送至戶籍所在地國稅局所屬分局、稽徵所、服務處，或於6月13日前就近送至任一國稅局所屬分局、稽徵所及服務處代收，即完成報稅手續了。</p>
        <p>比起自然人憑證，不僅可以省去親辦申請的流程與時間，取得成本也低，也不用擔心憑證遺失重新補發(可線上重新下載安裝)，更免去讀卡機插卡的問題，實是一舉數得。</p>
        <div align="right"><a href="javascript:history.go(-1);"><span class="btn_blue">返回</span></a></div>
    </div>;
};

const faq04 = () => {
    return <div>
        <div class="submenu">常見問題區</div>
        <div class="title1">4. 該如何申請金融憑證？</div>
        <p><span class="orange"><b>A：</b></span>可逕向金融憑證所屬各銀行、證券商、保險公司、金控公司申辦及洽詢相關下載憑證檔案作業流程。</p>
        <div align="right"><a href="javascript:history.go(-1);"><span class="btn_blue">返回</span></a></div>
    </div>;
};

const faq05 = () => {
    return <div>
        <div class="submenu">常見問題區</div>
        <div class="title1">5. 如何使用金融憑證報稅？</div>
        <p><span class="orange"><b>A：</b></span>您可以到相關網站下載報稅軟體與操作手冊,依照說明就可以成功的完成金融憑證報稅了。</p>
        <ul>
            <li>報稅軟體下載：<br />
            「綜合所得稅電子結算申報繳稅系統」財政部電子申報繳稅服務網站<br />
            <a href="http://tax.nat.gov.tw/declist.html">http://tax.nat.gov.tw/declist.html</a></li>
            <li>操作手冊下載：<br />
            財政部電子申報繳稅服務網站<br />
            <a href="http://tax.nat.gov.tw/info_IRX_user.html?id=1">http://tax.nat.gov.tw/info_IRX_user.html?id=1 </a></li>
        </ul>
        <div align="right"><a href="javascript:history.go(-1);"><span class="btn_blue">返回</span></a></div>
    </div>;
};

export { ctbc, yuanta, SSL, faq01, faq02, faq03, faq04, faq05};