import React from 'react'

import menu_1 from '../img/menu_01.jpg'
import menu_2 from '../img/menu_02.jpg'
import menu_3 from '../img/menu_03.jpg'
import menu_4 from '../img/menu_04.jpg'
import menu_5 from '../img/menu_05.jpg'

import menu_1_a from '../img/menu_01_a.jpg'
import menu_2_a from '../img/menu_02_a.jpg'
import menu_3_a from '../img/menu_03_a.jpg'
import menu_4_a from '../img/menu_04_a.jpg'
import menu_5_a from '../img/menu_05_a.jpg'

import qa_top from '../img/QA_top.jpg'

import { Link, Route, Switch } from 'react-router-dom';



class MainList extends React.Component {
    state = {
        img: menu_1, 
        img2: menu_2,
        img3: menu_3,
        img4: menu_4,
        img5: menu_5
    }

    stateOn = {
        img: menu_1_a, 
        img2: menu_2_a,
        img3: menu_3_a,
        img4: menu_4_a,
        img5: menu_5_a
    }

    

    render() {
        return (
            <table border="0" cellspacing="0" cellpadding="0" style={{ lineHeight: '0' }}>
            
            <tr>
                <td width="250" height="auto">
                    <img src={ qa_top } />
                </td>
                
                <td>           
                    <Link to="/">
                        <img src = { this.state.img }
                            onMouseOver = {
                                () => { this.setState({ img : menu_1_a }) }
                            }
                            onMouseOut = {
                                () => { this.setState({ img : menu_1 }) }
                            }
                        />
                    </Link>
                </td>

                <td>
                    <Link to="/news">
                        <img src = { this.state.img2 }
                            onMouseOver = {
                                () => { this.setState({ img2 : menu_2_a }) }
                            }
                            onMouseOut = {
                                () => { this.setState({ img2 : menu_2 }) }
                            }
                        />
                    </Link>
                </td>

                <td>
                    <Link to="/product/product_list">
                        <img src = { this.state.img3 }
                            onMouseOver = {
                                () => { this.setState({ img3 : menu_3_a }) }
                            }
                            onMouseOut = {
                                () => { this.setState({ img3 : menu_3 }) }
                            }
                        />
                    </Link> 
                </td>

                <td>
                    <a href="https://publicca.hinet.net/SSL-07.htm">
                        <img src = { this.state.img4 }
                            onMouseOver = {
                                () => { this.setState({ img4 : menu_4_a }) }
                            }
                            onMouseOut = {
                                () => { this.setState({ img4 : menu_4 }) }
                            }
                        />
                    </a>
                </td>

                <td>
                    <Link to="/duty">
                        <img src = { this.state.img5 } 
                            onMouseOver = {
                                () => { this.setState({ img5 : menu_5_a }) }
                            }
                            onMouseOut = {
                                () => { this.setState({ img5 : menu_5 }) }
                            }
                        />
                    </Link>
                </td>
            </tr>
            </table>
        );
    }
}

export default MainList


