import React from 'react';

import sample1 from "../img/smime1.jpg"
import sample2 from "../img/smime2.jpg"
import sample3 from "../img/smime3.jpg"
import sample4 from "../img/smime4.jpg"
import sample5 from "../img/smime5.jpg"
import sample6 from "../img/smime6.jpg"
import sample7 from "../img/smime7.jpg"
import sample8 from "../img/smime8.jpg"

const product11 = () => {
    return <div>
        <div class="submenu">安全電子郵件</div>
           
		<div class="gray_section" style = {{ marginBottom:'30px', textAlign:'justify', textJustify:'distribute' }}>
		網路詐騙郵件、釣魚郵件、甚至APT社交攻擊手法日新月異，還在尋找簡易、好用的解決方案?
        讓中華電信安全電子郵件憑證助您一臂之力，協助您的用戶能快速識别郵件是否來自您的組職 、降低遭受詐騙、與APT社交攻擊之風險。
        </div>

		<font style = {{ fontSize: '18px'}}><b>解決問題</b></font>
		
        <p class="gray_line"><img src = { sample1 }  width="100%"/></p>
      	<p class="gray_line"><img src = { sample2 }  width="100%"/></p>
      	<p class="gray_line"><img src = { sample3 }  width="100%"/></p>
      	<p class="gray_line"><img src = { sample4 }  width="100%"/></p>
        
        <font style = {{ fontSize: '18px'}}><b>技術資訊</b></font>
		<br></br>
        <br></br>
        S/MIME 是一種廣泛接受的通訊協定，用來傳送數位簽章郵件、和加密的郵件。
        而中華電信之S/MIME 憑證，可讓您利用您專屬之郵件憑證，將電子郵件進行進行數位簽章(證明郵件來自您的組織)、或內容加密(保護資料傳送、避免資料外洩後的風險)。
        

        <p class="gray_line"><img src = { sample5 }  width="100%"/></p>
        
        除此之外，S/MIME 提供密碼編譯的安全性服務，例如S/MIME 也可協助加強電子郵件訊息的資料安全性（使用加密）。
        
        <p class="gray_line"><img src = { sample6 }  width="100%"/></p>
        <p class="gray_line"><img src = { sample7 }  width="100%"/></p>
        <p class="gray_line"><img src = { sample8 }  width="100%"/></p>
        

		<div class="section">
        <p class="gray_line"></p>
		</div>

        <font style = {{ fontSize: '18px'}}><b>申請文件</b></font>
        <br></br>
        <br></br>

		<a href="/faca/smime_apply_doc_person.doc" download="中華電信通用憑證管理中心-SMIME憑證申請書-個人型.doc">SMIME憑證申請書-個人型下載</a> 
        <br></br>
		<a href="/faca/smime_apply_doc_company.doc" download="中華電信通用憑證管理中心-SMIME憑證申請書-組織型.doc">SMIME憑證申請書-組織型下載</a> 

		<div class="section">
        <p class="gray_line"></p>
		</div>
        
        <a href="https://hikey.hinet.net/gra/smime/home"><font style = {{ fontSize: '18px'}}><b>線上 SMIME 憑證申請入口網</b></font></a>
        <br></br>
        

        <div class="section">
			<p>連絡電話：02-2344-4820#4015<br></br>
			產品客服信箱：A_SignService@cht.com.tw<br></br>
			產品客服窗口：資分數安處企身科 黃柏舜先生</p>
		</div>
		
    </div>
};

export { product11 };