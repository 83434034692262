// /src/duty.js

import React from 'react';
import { Link } from 'react-router-dom';



/**
 * These are root pages
 */

const productContent = () => {
    return <div> 
        <div class="submenu">產品列表</div>
        <div class="gray_section" margin-bottom='30px'>    
        <div class="sort_list" style = {{ width:'50%', float:'left'}}>
            <ul>
                <Link to="/product/product01">
                    <li><font color={'black'}>證券下單憑證</font></li>
                </Link>

                <Link to="/product/product02">
                    <li><font color={'black'}>多憑證驗證系統(CHT HiPKIVA)</font></li>
                </Link>

                <Link to="/product/product03">
                    <li><font color={'black'}>安全聯網(SSL Relay)</font></li>
                </Link>

                <Link to="/product/product04">
                    <li><font color={'black'}>硬體密碼模組(HSM)</font></li>
                </Link>

                <Link to="/product/product09">
                    <li><font color={'black'}>快意簽A+Sign</font></li>
                </Link>
            </ul>
            </div>
        <div class="sort_list" style = {{ width:'50%', float:'right'}}>
            <ul>
                {
                    //<Link to="/product/product05" >
                    //    <li><font color={'black'}>藍芽讀卡機</font></li>
                    //</Link>
                }
                <Link to="/product/product06">
                    <li><font color={'black'}>NFC系統</font></li>
                </Link>
                
                <Link to="/product/product07">
                    <li><font color={'black'}>代理郵件簽章/加解密 系統</font></li>
                </Link>

                <Link to="/product/product08">
                    <li><font color={'black'}>金融FXML憑證</font></li>
                </Link>

                <Link to="/product/product10">
                    <li><font color={'black'}>時戳服務(TSA)</font></li>
                </Link>
                
                <Link to="/product/product11">
                    <li><font color={'black'}>安全電子郵件</font></li>
                </Link>
            </ul>
            </div>
            <div class="clear"></div>
        </div>
        
        {
            /*
            <p disabled ><strong>(1) 證劵下單憑證‧藍芽讀卡機</strong><br />
            連絡電話：02-2344-3998<br />
            聯絡信箱：<a href="mailto:caservice@cht.com.tw" target="_blank">caservice@cht.com.tw</a></p>
            */
        }

        <p>
        連絡電話：02-2344-3970<br />
        聯絡信箱：<a href="mailto:caservice@cht.com.tw" target="_blank">caservice@cht.com.tw</a></p>
        </div>;
};

export { productContent };