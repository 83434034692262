import React from 'react';

import product_08 from "../img/product_08.png"

const product04 = () => {
    return <div>  
        <div class="submenu">中華電信密碼安全模組(保密器) HiPKI SafGuard 1200 HSM</div>
    
        <div style={{paddingTop:'20px', float:'left'}}><img src = { product_08 } /></div>
        <div class="gray_section" style={{ width:'50%', marginBottom:'30px', float:'right'}}>歐美各國對於密碼模組的輸出大都有相當程度的管制措施，並且密碼安全模組的使用可能會影響整個國家的安全，所以建立本土的密碼模組研發技術是非常重要的。中華電信密碼安全模組為我國自行研發的密碼模組裝置，其安全設計已通過國際認可的密碼模組標準 NIST FIPS 140-2 Level 3 驗證，可應用於安全儲存各類伺服器的私密金鑰，以及數位簽章作業的安控單元，能有效強化伺服器的安全性及可信度。</div>
        <div class="clear"></div>
        
        <div class="section">
            <div class="title1">產品特色：</div>
            <ul class="gray_line">
            <li>鑰控管安全性高
                <ul class="nodisc">
                <li>一旦私密金鑰儲存到高速保密器後，所有的加解密運算都是在密碼安全模組中安全地執行，可有效避免私密金鑰遭受竊取。</li>
            </ul>
            </li>
            <li>加解密運算效率佳
                <ul class="nodisc">
                <li>設計專用的硬體執行加解密運算，其效能較軟體方式高出甚多，故伺服器軟體可空出時間提供更多且更穩定的服務。</li>
            </ul>
            </li>
            <li>嚴密安控啟動機制
                <ul class="nodisc">
                <li>利用安控IC卡由主管與作業人員共同控管保密器之啟動，防止非授權之使用。</li>
            </ul>
            </li>
            <li>完全自行研發技術，可依客戶需求進行客製化設計
                <ul class="nodisc">
                <li>高速保密器是由中華電信研究所自行研發的產品，掌握完整的技術解決方案，可依客戶的安全需求提供更安全的密碼演算法及不同的金鑰長度。</li>
                </ul>
            </li>
            <li>滿意的產品服務及技術支援
                <ul class="nodisc">
                <li>提供詳細的產品中文使用說明文件，以及堅強的專業技術支援人力，可配合客戶進行完整的教育訓練及顧問服務。</li>
                </ul>
            </li>
        </ul>
        </div>
        
        <div class="section">
            <div class="title1">產品主要功能：</div>
        <ul class="gray_line">
            <li>已通過國際認可的密碼模組標準 FIPS 140-2 Level 3 的安全規範</li>
            <li>依安全需求提供 2048、4096 位元等多種的 RSA 金鑰長度選擇</li>
            <li>內建真實亂數產生器(Physical RNG)，確保金鑰產生之亂度及品質</li>
            <li>具備預防竄改(Tamperproof)的開蓋金鑰銷毀功能</li>
            <li>具有開機啟動後自我檢查功能(Boot up self checking)</li>
            <li>依存取權限分成安控人員及作業人員兩種角色</li>
            <li>內建 IC 卡讀寫模組，具備金鑰分持、備份以及安全控管機制</li>
            <li>提供主機間的認證及授權存取安全控管</li>
            <li>提供安全稽核的事件記錄給稽核伺服器</li>
            <li>提供多組金鑰儲存(包含RSA、Triple DES 、 AES、ECC 等)</li>
            <li>支援密碼演算法
            <ol>
                <li>對稱式：Triple DES (FIPS certified), AES (FIPS certified)</li>
                <li>非對稱：RSA (FIPS certified)</li>
                <li>雜湊函數：SHA-1, SHA-224, SHA-256, SHA-384, SHA-512 (FIPS certified) </li>
                <li>亂數產生器 : DRBG(FIPS certified)</li>
            </ol>
            </li>
            <li>支援API : PKCS#11, Microsoft CSP</li>
            <li>Web管理介面，全中文化介面，操作簡易明瞭</li>
            <li>具有憑證管理機制，憑證需求檔製作、憑證簽發、與憑證管理</li>
        </ul>
        </div>
        
        <div class="section">
            <div class="title1">產品技術規格：</div>
        <ul class="gray_line">
            <li>演算法：
            <ol>
                <li>對稱式
                    <ul>
                        <li>Triple DES, 3-keyECB and CBC mode (FIPS certified)<br />
                        - <span class="orange">Triple DES</span> 執行效能為 <span class="orange">≧ 300MBits/Sec</span></li>
                    <li>AES 128/192/256bits, ECB and CBC mode (FIPS certified)<br />
                        - <span class="orange">AES 128-bit</span> 執行效能為 <span class="orange">≧ 960MBits/Sec</span><br />
                        - <span class="orange">AES 192-bit</span> 執行效能為 <span class="orange">≧ 800MBits/Sec</span><br />
                        - <span class="orange">AES 256-bit</span> 執行效能為 <span class="orange">≧ 685MBits/Sec</span></li>
                </ul>
                </li>
                <li>非對稱
                    <ul>
                        <li>RSA 1024-bit ,簽章效能200次/秒. (RSA 1024: 80 TPS) (FIPS certified)</li>
                        <li>RSA 2048-bit ,簽章效能100次/秒. (FIPS certified)</li>
                </ul>
                </li>
                <li>雜湊函數
                    <ul>
                        <li>SHA-1,執行效能為≧ 320MBits/Sec. (FIPS certified) </li>
                    <li>SHA-224,執行效能為≧ 200MBits/Sec. (FIPS certified)</li>
                    <li>SHA-256,執行效能為≧ 200MBits/Sec. (FIPS certified)</li>
                    <li>SHA-384,執行效能為≧ 320MBits/Sec. (FIPS certified)</li>
                    <li>SHA-512,執行效能為≧ 320MBits/Sec. (FIPS certified)</li>
                </ul>
                </li>
                <li>亂數產生器
                    <ul>
                        <li>DRBG(FIPS certified)</li>
                </ul>
                </li>
            </ol>
            </li>
            <li>支援API</li>
            <li>RS232控制 IPv4 Ethernet port Enable與IP assign</li>
            <li>連接界面：提供Ethernet 10/100Mbps RJ-45 x 4</li>
            <li>憑證需求檔符合 pkcs#10，憑證金鑰檔符合 pkcs#12</li>
            <li>使用者 Web 管理界面</li>
            <li>實體尺寸：約 430 mm (長) x 380 mm (寬) x 90 mm (高) </li>
            <li>輸入電壓：AC 110V</li>
            <li>工作溫度：18 ~ 28 ℃</li>
            <li>相對溼度：20% ~ 85% 在 25 ℃</li>
            <li>認證：
                <ol>
                <li>FCC: CFR47, Part 15, Subpart B, Class B</li>
                <li>FIPS 140-2 level 3</li>
            </ol>
            </li>
            <li>生產國家：中華民國</li>
            <li>組成套件：
                <ol>
                <li>HiPKI SafGuard 1000 HSM SI 一台</li>
                <li>光碟片一片(內含金鑰管理工具、驅動程式、系統程式發展軟體(SDK)和完整的安裝與使用說明書的電子檔案)</li>
            </ol>
            </li>
        </ul>
        </div>
        
        <div align="right"><a href="javascript:history.go(-1);"><span class="btn_blue">返回</span></a></div>
        
    </div> 
};



export { product04 };