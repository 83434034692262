import React from 'react';
import Header from './components/Header';
import MainList from './components/MainList';
import QAList from './components/QAList';
import Footer, { privacy } from './components/Footer';
import SubMenu from './components/SubMenu';
import AD from './components/AD';
import './App.css';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ctbc, yuanta, SSL, faq01, faq02, faq03, faq04, faq05 } from './static/subMenuContent';
import { dutyContent } from './static/duty';
import { productContent } from './static/productContent';
import { product01 } from './static/product01';
import { product02 } from './static/product02';
import { product03 } from './static/product03';
import { product04 } from './static/product04';
import { product05 } from './static/product05';
import { product06 } from './static/product06';
import { product07 } from './static/product07';
import { product08 } from './static/product08';
import { product09 } from './static/product09';
import { product10 } from './static/product10';
import { product11 } from './static/product11';


class App extends React.Component {
    render() {
        return (
            <div class="main">
                <Header />
                <MainList />
                <table width="1000" border="0" cellspacing="0" cellpadding="0">
                    <td rowspan="2" valign="top" class="QA_outer" width="250">
                        <QAList />
                    </td>
                    <td>
                        <div class="cont">
                            <Switch>
                                <Route path="/" exact component={ctbc} />
                                <Route path="/result2" component={yuanta} />
                                <Route path="/result3" component={SSL} />
                                <Route path="/faq01" component={faq01} />
                                <Route path="/faq02" component={faq02} />
                                <Route path="/faq03" component={faq03} />
                                <Route path="/faq04" component={faq04} />
                                <Route path="/faq05" component={faq05} />
                                <Route path="/duty" component={dutyContent} />
                                <Route path="/privacy" component={privacy} />
                                <Route path="/product/product_list" component={productContent} />
                                <Route path="/product/product01" component={product01} />
                                <Route path="/product/product02" component={product02} />
                                <Route path="/product/product03" component={product03} />
                                <Route path="/product/product04" component={product04} />
                                <Route path="/product/product05" component={product05} />
                                <Route path="/product/product06" component={product06} />
                                <Route path="/product/product07" component={product07} />
                                <Route path="/product/product08" component={product08} />
                                <Route path="/product/product09" component={product09} />
                                <Route path="/product/product10" component={product10} />
                                <Route path="/product/product11" component={product11} />
                                <Route component={ctbc} />
                            </Switch>
                        </div>
                    <AD />
                    </td>
                </table>
                <Footer />
            </div>
        );
    }
}

export default App;
