import React from 'react'

import q1 from '../img/Q1.png'
import q2 from '../img/Q2.png'
import q3 from '../img/Q3.png'
import q4 from '../img/Q4.png'
import q5 from '../img/Q5.png'

import { Link } from 'react-router-dom';

class QAList extends React.Component {
    render() {
        return (
            <div class="QA_inner" >
                <table border="0" cellspacing="0" cellpadding="5" align="center">
                <tr>
                    <td><img src={ q1 } /></td>
                    <td class="QA_cont"><Link to="/faq01">什麼是金融憑證？</Link></td>
                </tr>
                <tr>
                    <td><img src={ q2 } /></td>
                    <td class="QA_cont"><Link to="/faq02">我有金融憑證可以作什麼？</Link></td>
                </tr>
                <tr>
                    <td><img src={ q3 } /></td>
                    <td class="QA_cont"><Link to="/faq03">使用金融憑證報稅的好處？</Link></td>
                </tr>
                <tr>
                    <td><img src={ q4 } /></td>
                    <td class="QA_cont"><Link to="/faq04">該如何申請金融憑證？</Link></td>
                </tr>
                <tr>
                    <td><img src={ q5 } /></td>
                    <td class="QA_cont"><Link to="/faq05">如何使用金融憑證報稅？</Link></td>
                </tr>
                </table>
            </div>
        );
    }
}

export default QAList


