import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, HashRouter} from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './App.css';


ReactDOM.render(
    (
        //<BrowserRouter basename={'/faca'}>
        <HashRouter>
            <Switch>
                    <App />     
            </Switch>
        </HashRouter>
    ),
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
