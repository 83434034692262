import React from 'react';

const product07 = () => {
    return <div>
        <div class="submenu">代理郵件簽章/加解密 系統</div>
        
        <div class="section">
            <div class="title1">本產品用途說明：</div>
            <ul class="gray_line">
            <li>代理簽章服務，將原本未簽章的電子郵件加上數位簽章</li>
            <li>可設定多組不同的簽章帳號/簽章金鑰對，根據不同的帳號來使用不同的金鑰</li>
            <li>簽章金鑰需保存在簽章代理伺服器上，有加密控管</li>
            <li>Windows/Linux平台皆可安裝</li>
            </ul>
        </div> 
        
        <div class="section">
            <div class="title1">產品特色介紹：</div>
            <ul class="gray_line">
            <li><strong>與主要Mail伺服器整合</strong>
                <ul>
                    <li>經由設定主Mail伺服器，將特定寄件者原本要外送的郵件先送至簽章代理伺服器，簽章後再送回主Mail伺服器。</li>
                    <li>優點是只需要建構一台簽章伺服器，並可使用原本的主Mail伺服器認證程序。應用的AP不需要做任何修改</li>
                    <li>缺點是主Mail伺服器需搭配這樣的傳送程序進行修改</li>
                </ul>
            </li>
            <li><strong>獨立提供各AP服務</strong>
                <ul>
                    <li>各AP伺服器將原本要送往主Mail伺服器的信件改送至簽章代理伺服器，簽章後再送往主Mail伺服器</li>
                    <li>優點：只需建構一台伺服器，各AP僅需更改Mail伺服器的位址，主Mail伺服器無需修改</li>
                    <li>缺點：無法整合至既有的認證方式</li>
                </ul>
            </li>
            <li><strong>各AP獨立管理簽章代理伺服器</strong>
                <ul>
                <li>為每台AP伺服器建置簽章代理伺服器(可裝在同一台機器上)，各AP伺服器均使用自己的代理伺服器進行簽章</li>
                <li>優點：金鑰仍由各AP伺服器管理，沒有需要認證的問題，不需修改主Mail伺服器</li>
                <li>缺點：需安裝多台簽章代理伺服器</li>
                </ul>
            </li>
            </ul>
        </div>
        <div align="right"><a href="javascript:history.go(-1);"><span class="btn_blue">返回</span></a></div>     
    </div>
};



export { product07 };