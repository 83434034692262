import React from 'react';

import intro_illus_06_1 from "../img/intro_illus_06-1.png"
import intro_illus_06_2 from "../img/intro_illus_06-2.jpg"
import intro_illus_06_3 from "../img/intro_illus_06-3.jpg"
import intro_illus_06_4 from "../img/intro_illus_06-4.jpg"
import intro_illus_06_5 from "../img/intro_illus_06-5.jpg"
import intro_illus_06_6 from "../img/intro_illus_06-6.jpg"
import intro_illus_06_7 from "../img/intro_illus_06-7.jpg"
import intro_illus_06_8 from "../img/intro_illus_06-8.jpg"
import intro_illus_06_9 from "../img/intro_illus_06-9.jpg"
import intro_illus_06_10 from "../img/intro_illus_06-10.jpg"

const product06 = () => {
    return <div>
        <div class="submenu">NFC系統</div>
        
        <div class="section">
            <div class="title1">中華電信可信賴的服務管理 TSM平台：</div>
            <div align="center"><img src = { intro_illus_06_1 } /></div><ul class="gray_line"><ol><li>服務提供者(SP)於TSM平台註冊並提供應用服務</li>
            <li>應用服務於CHT TSM平台上架</li>
            <li>NFC手機用戶可連網於應用服務清單上下載所需服務</li>
            </ol>
            </ul>
        </div> 
        
        <div class="section">
            <div class="title1">手機儲存方式介紹：</div>
            <p class="gray_line"><img src = { intro_illus_06_2 } /></p>
        </div>
        
        <div class="section">
            <div class="title1">中華電信取得國際信用卡公司授權：</div>
            <p class="gray_line"><img src = { intro_illus_06_3 } /></p>
        </div>
        
        <div class="section">
            <div class="title1">目前TSM平台以測試可支援的裝置與手機：</div>
            <p class="gray_line">本產品可支援各大品牌手機廠牌出產之手機,並可應用於不同手機作業系統,相關支援詳細資訊可於<a href="http://www.nfc-phones.org/certified-phones/3/" target="_blank">http://www.nfc-phones.org/certified-phones/3/</a>查詢<br />
            <img src = { intro_illus_06_10 } width="670" height="409" /></p>
        </div>
        
        <div class="section">
            <div class="title1">應用實績 ：</div>
            <p class="gray_line">本項產品可應用於智慧手機內的數位皮夾、透過平台以Hami智慧錢包下載應用程式等方式，提供您便利的應用服務。</p><br />
            <div class="p06img"><img src = { intro_illus_06_4 } />
            <img src = { intro_illus_06_5 } />
            <img src = { intro_illus_06_6 } />
            <img src = { intro_illus_06_7 } />
            <img src = { intro_illus_06_8 } />
            <img src = { intro_illus_06_9 } /></div></div>
        <div align="right"><a href="javascript:history.go(-1);"><span class="btn_blue">返回</span></a></div>     
    </div>
};



export { product06 };