import React from 'react';

import sample from "../img/TSA2.JPG"
import sample2 from "../img/TSA01.JPG"

const product10 = () => {
    return <div>
        <div class="submenu">中華電信時戳服務</div>
           
		<div class="gray_section" style = {{ marginBottom:'30px', textAlign:'justify', textJustify:'distribute' }}>
		時戳服務符合RFC 3161之標準，可提供使用者對任一電子文件附加時戳符記(time-stamp token)的功用，服務內容包含押時戳及儲存、查詢及驗證時戳符記，此服務適合應用於舉證某電子文件於某個時間點已存在或是為高價格/重要之交易或合約提供強力可信任之時間點佐證。 

        </div>

		<font style = {{ fontSize: '18px'}}><b>中華電信時戳服務優勢</b></font>
		<ul class="gray_line">
		  <li><font style = {{ fontSize: '15px' }}>國內首家提供可信任時戳服務廠商，具有中華電信背書之可靠第三方機構。</font>
		  </li>
		  <li><font style = {{ fontSize: '15px' }}>預設採全球GPS為時間源，並有專線連接至國家標準時間源，與國際標準UTC時間比較，精準度時間差可達細微50ns以下。</font>
		  </li>
		  <li>	
                <div> 
                    <font style = {{ fontSize: '15px' }}>服務內容包含下列四點：</font>
                </div>	
			    <div style = {{ float:'left', width: '50%'}} >
                    <ul class="nodisc" style = {{ paddingLeft: '20px' }}>
                        <font color="#448aca">
                            &#10004; 押時戳符記<br/>
                            &#10004; 保存時戳歷程<br/>
                        </font>	
                    </ul>
                </div>	
                <div style = {{ float:'left', width: '50%' }}>
                <ul class="nodisc" style = {{ paddingLeft: '20px' }}>
                        <font color="#448aca">
                            &#10004; 供使用者查詢時戳歷程<br/>
                            &#10004; 提供時戳符記佐證<br/>
                        </font>	
                    </ul>
                </div>	
		  </li>
		</ul>
		<br/>
		<div class="section">
        <p class="gray_line"></p>
		</div>

		<div>
			<div style = {{ float:'left', width: '50%'}} >
				<font style = {{ fontSize: '18px' }}><b>時戳服務國際應用場域</b></font>
				<ul class="gray_line">
				<li>電子醫療 (Electronic medical)
					<ul class="nodisc" style = {{ paddingLeft: '20px' }}>
					<font color="#448aca">
						&#10004; 電子病歷<br/>
						&#10004; 醫療文件<br/><br/>
					</font>	
					</ul>
				</li>
				<li>金融業 (Financial Industry)
					<ul class="nodisc" style = {{ paddingLeft: '20px' }}>
					<font color="#448aca">
						&#10004; 電子報價<br/>
						&#10004; 電子契約<br/>
						&#10004; 融資契約書<br/><br/>
					</font>	
					</ul>
				</li>
				<li>電子商務 (E-Commerce)
					<ul class="nodisc" style = {{ paddingLeft: '20px' }}>
					<font color="#448aca">
						&#10004; 電子契約<br/>
						&#10004; 電子發票<br/>
						&#10004; 電子帳簿<br/>
					</font>	
					</ul>
				</li>
                </ul>
			</div>	
			<div style = {{ float:'left', width: '50%' }}>
				<br/>
				<li style = {{ paddingTop: '12px' }}>智慧財產管理 (Intellectual property)<br/>
					<font color="#448aca" style = {{ paddingLeft: '40px' }}>
						&#10004; 智慧財產權證明<br/>
					</font>	
					<font color="#448aca" style = {{ paddingLeft: '40px' }}>
						&#10004; 著作權歸檔文件<br/>
					</font>	
				</li>
				<li style = {{ paddingTop: '26px' }}>政府機關 (Government agencies)<br/>
					<font color="#448aca" style = {{ paddingLeft: '40px' }}>
						&#10004; 數位證書<br/>
					</font>	
					<font color="#448aca" style = {{ paddingLeft: '40px' }}>
						&#10004; 電子公文<br/>
					</font>	
					<font color="#448aca" style = {{ paddingLeft: '40px' }}>
						&#10004; 電子公報<br/><br/>
					</font>	
				</li>
			</div>
		</div>
<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
		<div class="section">
        <p class="gray_line"></p>
		</div>
		
		<div>
		<font style = {{ fontSize: '18px' }}><b>時戳符記嵌入PDF文件展示</b></font>
		<ul class="gray_line">
		  
		</ul>
		
		</div>
      	<p class="gray_line"><img src = { sample2 }  width="80%"/></p>
		
		<div class="section">
        <p class="gray_line"></p>
		</div>
		
		<font style = {{ fontSize: '18px' }}><b>產品整合與應用情境</b></font>
		<ul>
		  <li>透過時戳機構來達成長時間保存可靠性。
		  </li>
		  <li>可用於佐證營業秘密或研發成果的存在時間點，或者為高價值或高風險文件提供簽署時間證明，進而主張先用權或提供舉證效力。
		  </li>
		</ul>
		

      	<p class="gray_line"><img src = { sample } width="100%"/></p>
		
        <div class="section">
			<p>連絡電話：02-2344-4820#4016<br></br>
			產品聯絡信箱：timestamp@cht.com.tw<br></br>
			產品聯絡窗口：資分數安處企身科 郭先生</p>
		</div>
		
    </div>
};

export { product10 };