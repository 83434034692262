import React from 'react';

import product_07_png from "../img/product_07.png"

const product03 = () => {
    return <div>  
        <div class="submenu">安全聯網系統(SSL VPN)</div>
    
        <div style={{padding:'30px', float:'left'}}><img src = { product_07_png } /></div>
        <div class="gray_section" >本安全系統平台系統架構符合SSL(Secure Socket Layer) /TLS(Transport Layer Security)安全通訊協定，提供網路應用程式在網際網路上做資料傳輸與電子交易所需的安全保護功能。對Client 與Server間傳送的資料提供資料加密(Encryption)資料隱密性及完整性保護。可搭配GCA憑證、與密碼控管等多樣組合，提供驗證機制、繞路轉接授權登入控制機制。同時也針對電子閘門伺服軟體金鑰提供高安全的儲存媒介。
        </div>
        <div class="clear"></div><br/>
        
        <div class="section">
            <div class="title1">產品特色：</div>
            <ul class="gray_line">
            <li>提供一個安全的通訊環境
                <ul class="nodisc">
                <li>安全聯網不僅讓用戶端以應用程式透通(transparency)方式安全轉接，更讓遠處位於轉接系統後的應用程式伺服端安全地接收與發送通訊資料，能夠有效地防止機密資料(sensitive information)在傳送過程中外洩(disclosure)、防止非法人員對傳送資料篡改及防止非法人員冒名傳送假資料，以確保訊息來源身份的辨識。</li>
            </ul>
            </li>
            <li>提供便宜、簡單的解決方案
                <ul class="nodisc">
                <li>VPN在金鑰管理上的多樣性與複雜性，反而影響到不同品牌，甚至同品牌不同類型VPN產品間的相容性。而系統，使用SSL/TLS的機制，不僅金鑰交換、密鑰產生、與資料加解密皆是由SSL/TLS協定完成，因此安全聯網系統比任何VPN產品皆有價格上的競爭優勢。</li>
            </ul>
            </li>
            <li>採用強化的安全機制
                <ul class="nodisc">
                <li>安全聯網系統特地針對SSL/TLS協定上的弱點，作防範與加強，使用AES等安全度較高的對稱式密碼演算法，並且將支援系統中最重要的私密金鑰寫入硬體安全模組(HSM)中。能有效地增加系統對內對外的安全度，確保系統運作安全無虞。</li>
            </ul>
            </li>
            <li>相容於瀏覽器密碼機制
                <ul class="nodisc">
                <li>一般瀏覽器(Browser)皆內建SSL/TLS功能，可以與具備SSL/TLS功能的網頁提供伺服器建立起加密通道，使用者更可以將自己的憑證匯入瀏覽器中，作為遠端網頁提供伺服器確認使用者身份之用。這樣的機制同樣可以轉移到瀏覽器與安全聯網系統，兩者之間不僅可以建立起SSL/TLS加密通道，以確認使用者的真實身份，在確認使用者身份後，再提供使用者安全通訊轉接的功能。</li>
            </ul>
            </li>
        </ul>
        </div>
        
        <div class="section">
            <div class="title1">安全聯網規格：</div>
        <ul class="cjk">
            <li>憑證管理
                <ol>
                <li>憑證格式符合X509規範</li>
                <li>憑證簽發：簽發伺服器憑證、簽發使用者憑證</li>
                <li>憑證需求檔: 英文需求檔、中文需求檔</li>
                <li>憑證載入，匯出，管理伺服器憑證</li>
                <li>憑證瀏覽中文顯示</li>
                <li>憑證需求檔符合pkcs#10，憑證金鑰檔符合pkcs#12</li>
                <li>系統管理備援機制全中文介面控管 </li>
                <li>控管介面Browser介面，系統參數備份與載入</li>
            </ol>
            </li>
            <li>連線管理
                <ol>
                <li>連線設定與管理</li>
                <li>網路TCP內文，SSL加密封裝與解封裝</li>
                <li>SSL VPN 連線協定轉接</li>
                <li>支援SSL單向或雙向認證與訊息加密</li>
                <li>管理者、使用者連線事件記錄(log)</li>
                <li>連線失敗原因線上查詢</li>
                <li>網路的繞路介接管理，包含TCP/IP、POP、HTTP之規約<br />
                </li>
                <li>驗證連線支援至RSA2048</li>
                <li>SSL連線加密支援AES256</li>
                <li>內建連線防火牆</li>
            </ol>
            </li>
            <li>金鑰管理
                <ol>
                <li>內建RSA1024、2048金鑰對產製</li>
                <li>私密金鑰備援回復</li>
                <li>私密金鑰禁止明碼匯出</li>
                <li>支援私密金鑰儲存硬體安全模組(中華電信SafGuard 1000 HSM FIPS 140-2 level 3另購)</li>
            </ol>
            </li>
            <li>驗證管理
                <ol>
                <li>PKI憑證鏈驗證</li>
                <li>多組憑證鏈之驗證</li>
                <li>使用者與伺服器雙向憑證認證</li>
                <li>連線憑證效期檢驗</li>
                <li>連線憑證IP檢驗</li>
                <li>管理者密碼認證</li>
                <li>遠端管理者遠端IP認證</li>
            </ol>
            </li>
            <li>硬體規格
                <ol>
                <li>具備Ethernet 10/100  RJ45</li>
                <li>CISC (Complex Instruction Set Computers) CPU</li>
                <li>LAN Bypass 具有Disable/Force/Watch Dog Mode網路備援</li>
                <li>Dimension (W x D x H) (19”/1u)</li>
                <li>嵌入式Linux作業系統</li>
            </ol>
            </li>
        </ul>
        </div>
        
        <div align="right"><a href="javascript:history.go(-1);"><span class="btn_blue">返回</span></a></div>
        
    </div>
};



export { product03 };