import React from 'react';

import intro_illus_06_1 from "../img/intro_illus_06-1.png"

const product08 = () => {
    return <div>
<div class="submenu">金融FXML憑證</div>
        <div class="section">
        <div class="title1">銀行業對於金融憑證的法規面要求</div>
      	<ul class="gray_line">
        <li>銀行主管機關
        <ul><li>行政院金管會銀行局:對於任何新業務的推行必須由銀行進行報部審核</li></ul></li>
        <li>憑證的主管機關<ul><li>經濟部商業司:任何可營運的憑證機構都必須將憑證實務作業基準送交審查並通過後始可對外提供服務</li></ul></li>
        <li>制定金融憑證的安全規範單位
        <ul><li>中華民國銀行商業同業公會全國聯合會</li>
        <li>包含4個會員公會，即台北市銀行商業同業公會、台灣省銀行商業同業公會聯合會、高雄市銀行商業同業公會及新北市銀行商業同業公會，而各會員公會所屬會員總機構，截至2012年底止，合計為61家 </li>
        </ul>
        </li>
            <li>銀行公會制定與憑證相關之規範
                <ul><li>金融XML憑證共通性技術規範</li>
                    <li>金融XML訊息建置指引</li>
                    <li>金融機構辦理電子銀行業務安全控管作業基準</li>
                    <li>金融憑證政策</li>
                    <li>最高金融憑證管理中心實務作業基準</li>
                    <li>金融憑證政策中心實務作業基準 </li>
                </ul>
            </li>
      	  <li>金融憑證機構須自行制定之規範
          	<ul><li>金融用戶憑證管理中心實務作業基準</li>
          	</ul>
          </li>
       	  <li>電子銀行業務之交易類別及風險之定義
          	<ul>
          	    <li>係指由客戶端利用電子設備及通訊設備以連線方式發送訊息至金融機構進行交易指示之交易類別，並依據其執行結果對客戶權益之影響區分風險之高低
                    <table border="0" cellpadding="0" cellspacing="0" class="table01" width="600">
                        <tr>
                            <th width="110" nowrap="nowrap">交易風險類別</th>
                            <th>說明</th>
                        </tr>
                        <tr>
                            <td align="center">高風險</td>
                            <td>係指該訊息執行結果，對客戶權益有重大影響之各類電子轉帳及交易指示。</td>
                        </tr>
                        <tr>
                            <td align="center">低風險</td>
                            <td>係指該訊息執行結果對客戶權益無重大影響之各類電子轉帳及交易指示。
                            <ul><ol><li>事先約定轉入帳戶。</li>
                            <li>概括約定及限定性繳費繳稅之稅費轉帳。</li>
                            <li>客戶非直接獲取金融機構之服務且需其人工後續認定客戶身分者之申請指示類。</li>
                            <li>非約定轉入帳戶
                            <ul><ol><li>ATM、POS等之低風險性交易，其限額應符合現行ＡＴＭ作業及ＰＯＳ作業相關規定。</li>
                            <li>網際網路之低風險性交易，以每戶每筆不超過五萬元、每天累積不超過十萬元、每月累積不超過二十萬元為限。</li>
                            <li>配合採用各種嚴密的技術防護措施時，其非約定轉入帳戶之轉帳限額，可由個別金融機構視其風險承擔之能力斟酌予以適當提高。</li></ol></ul></li></ol></ul>
                        </td>
                        </tr>
                    </table>
                </li>
          	</ul>
          </li>
      	  <li>銀行提供高風險交易使用之認證工具
          	<ul><li>法人薪資戶非約定轉帳</li>
          	  <li>僅開放憑證</li>
          	  <li>個人金融非約定轉帳</li>
          	  <li>各家銀行提供憑證、晶片金融卡或OTP等工具作為交易認證工具</li>
          	</ul>
          </li>
       </ul>
      </div> 
      
        <div class="section">
      	<div class="title1">金融憑證的現況：</div>
      	<ul class="gray_line">
            <li><strong>何謂FEDI</strong>
                <ul>
                <li>金融電子資料交換(Financial DI,FEDI)，簡稱“金融ＥＤＩ”，係指企業或個人利用電腦作業，以特定的標準格式，經由通訊網路與金融機構連線，進行企業之自行、跨行及跨網之付款、資金調撥及轉帳等金融服務</li>
                <li>國內以聯合國標準UN/EDIFACT( Electronic Data Interchange for Administration Commerce and Transport )進行EDI之作業包括關貿網路的海空運 貨物通關自動化作業、商業司推動流通業、電子業電子訂貨作業、工業局主導汽車業、製造業自動化作業，以及籌劃中的環保、圖書、航港等 EDI 作業</li>
                <li>封閉式環境 </li>
                </ul>
            </li>
            <li><strong>金融EDI的用途</strong>
                <ul>
                    <li>各AP伺服器將原本要送往主Mail伺服器的信件改送至簽章代理伺服器，簽章後再送往主Mail伺服器</li>
                    <li>優點：只需建構一台伺服器，各AP僅需更改Mail伺服器的位址，主Mail伺服器無需修改</li>
                    <li>缺點：無法整合至既有的認證方式</li>
                </ul>
            </li>
            <li><strong>各AP獨立管理簽章代理伺服器</strong>
                <ul>
                <li>電子轉帳(自行/跨行)</li>
                <li>線上扣繳海關稅費</li>
                <li>跨網及關係戶扣款
                <ul><li>跨網扣款：企業可利用開立帳戶銀行之XML憑證於其他行庫(需有加入EDI系統）所開立之帳戶逕予扣帳，轉入自行或其他帳戶 </li>
                <li>具關係戶（企業及個人）所開立之不同帳戶可授權共用一張開立帳戶銀行之XML憑證逕予扣帳，轉入自行或其他帳戶 </li>
                </ul>
                </li>
                </ul>
            </li>
            <li><strong>何謂FXML</strong>
			<ul>
                <li>從事金融相關電子交易時使用。由用戶憑證機構以數位簽章方式簽發載有憑證用戶之簽章驗證資料，含憑證用戶之身分識別，用以確認憑證用戶身分、資格之電子形式證明，如同紙本之印鑑證明</li>
                <li>依循中華民國銀行商業同業公會全國聯合會訂定之金融XML訊息規格建置「網際網路FXML金融交易跨行支付平台」，每次於線上辦理自行及跨行轉帳交易時，以金融憑證作數位簽章加密。跨行交易透過財金資訊股份有限公司「金融XML共用系統」辦理清算</li>
                <li>開放式環境</li>
			</ul>
            </li> 
        <li><strong>金融FXML的用途</strong>
                    <ul>
        <li>即時及預約交易</li>
        <li>單筆付款指示、多筆付款指示</li>
        <li>約定轉入帳戶及非約定轉入帳戶轉帳交易</li>
        <li>自行及跨行轉帳交易 </li>
                    </ul>
        </li> 
        <li><strong>加入FXML業務之銀行清單</strong>
        <dl class="bank_list"><dd>4	臺灣銀行</dd>
        <dd>5	臺灣土地銀行</dd>
        <dd>6	合作金庫商業銀行</dd>
        <dd>7	第一銀行</dd>
        <dd>8	華南商業銀行</dd>
        <dd>9	彰化商業銀行</dd>
        <dd>11	上海商業儲蓄銀行</dd>
        <dd>12	台北富邦銀行</dd>
        <dd>13	國泰世華銀行</dd>
        <dd>16	高雄銀行</dd>
        <dd>17	兆豐商業銀行</dd>
        <dd>21	花旗(台灣)銀行</dd>
        <dd>39	澳商澳盛銀行</dd>
        <dd>40	中華開發工銀</dd>
        <dd>48	臺灣工業銀行</dd>
        <dd>50	臺灣中小企業銀行</dd>
        <dd>52	渣打商業銀行</dd>
        <dd>53	台中銀行</dd>
        <dd>54	京城銀行</dd>
        <dd>81	滙豐(台灣)商銀</dd>
        <dd>101	大台北銀行</dd>
        <dd>102	華泰銀行</dd>
        <dd>103	臺灣新光銀行</dd>
        <dd>108	陽信商業銀行</dd>
        <dd>118	板信銀行</dd>
        <dd>147	三信商銀</dd>
        <dd>700	中華郵政</dd>
        <dd>803	聯邦銀行</dd>
        <dd>805	遠東銀行</dd>
        <dd>806	元大商業銀行</dd>
        <dd>807	永豐商業銀行</dd>
        <dd> 808	玉山商業銀行</dd>
        <dd>809	萬泰銀行</dd>
        <dd>810	星展(台灣)銀行</dd>
        <dd>812	台新銀行</dd>
        <dd>814	大眾銀行</dd>
        <dd>815	日盛商業銀行</dd>
        <dd>816	安泰銀行</dd>
        <dd>822	中國信託銀行</dd>
        </dl>
        <div class="clear"></div>
        </li>        
      	</ul>
        </div>
        <div align="right"><a href="javascript:history.go(-1);"><span class="btn_blue">返回</span></a></div>     
    </div>
};

export { product08 };