import React from 'react';

import intro_illus_04_1 from "../img/intro_illus_04-1.png"
import intro_illus_04_2 from "../img/intro_illus_04-2.png"
import intro_illus_04_3 from "../img/intro_illus_04-3.png"
import intro_illus_04_4 from "../img/intro_illus_04-4.png"

const product02 = () => {
    return <div>
        <div class="submenu">多憑證驗證系統(CHT HiPKIVA)</div>
        <div class="gray_section">中華電信HiPKI網路認證套件包，一個可以讓您迅速導入PKI安全應用的魔法包。<br /><br />
        中華電信多年來受我國政府委託進行PKI基礎建設的研發與建置，具有最扎實的PKI技術能力，採用中華電信原廠提供的PKI套件，將是您最安心的選擇！ 
        </div><br />
      
        <div class="section">
            <div class="title1">產品簡介：</div>
            <p class="gray_line">CA為佈局公開金鑰基礎建設時不可或缺的一員，但又覺得設立一個CA很麻煩嗎? 現在中華電信「Public CA」提供您現成的CA，「Public CA」已向經濟部申請設立通過，系統維運也已通過 ISO 27001認證並符合WebTrust for CA等級的認證。您不必自己建置及維運CA系統，只要提供您的需求，我們即可迅速建立您的專屬RA群組，使用起來就猶如您自己有一套專屬的CA系統，並可在短時間內提供憑證測試環境供您實際操作，絕對是您在佈局公開金鑰基礎建設時最好的選擇。</p>
        </div> 
      
        <div class="section">
            <div class="title1">產品特色：</div>
            <ul class="gray_line">
            <li>完整支援我國政府PKI所有種類的IC卡與憑證</li>
            <li>完整支援商用PKI的IC卡與憑證</li>
            <li>提供PKI身分認證、簽驗章、加解密等全方位的速成元件，包含完整的Client端元件及Server端元件套裝，直接整合，安裝簡易，即時可用</li>
            <li>Client端元件提供現成可用的ActiveX元件</li>
            <li>Server端元件提供ASP COM元件及Java元件兩種版本，支援多種作業平台，可充分發揮現有伺服主機的能力，不必另外購買專用的伺服主機</li>
            </ul>
        </div>
      
        <div class="section">
            <div class="title1">產品架構：</div>
            <p class="gray_line"><img src = { intro_illus_04_1 } /></p>
        </div>
      
        <div class="section">
            <div class="title1">應用情境：</div>
            <ul class="gray_line">
            <li>企業會員網站身份認證與登入<img src = { intro_illus_04_2 }/></li>  
            <li>購物網站電子交易<img src = { intro_illus_04_3 }/></li>
            <li>薪資單郵件加解密<img src = { intro_illus_04_4 } /></li>
            </ul>
        </div>
      
        <div class="section">
            <div class="title1">產品功能 ：</div>
            <ul class="gray_line">
            <li>憑證IC卡登入驗證
                <ul>
                <li>登入認證採用挑戰與回應(Challenge and Response)機制，可防禦「重送攻擊(Replay Attack)」</li>
                <li>可設定排程下載憑證廢止清冊(CRL)</li>
                </ul>
            </li>
            <li>數位信封及數位簽章
                <ul>
                <li>可產製符合PKCS#7/CMS標準之數位信封及數位簽章格式</li>
                <li>提供128位元以上對稱式金鑰加密</li>
                </ul>
            </li>
            <li>憑證驗證
                <ul>
                <li>已內建憑證驗證模組，不必另案購買，經濟可靠</li>
                <li>亦可與本公司提供的憑證驗證服務系統搭配使用，以獲得更高的憑證驗證效能</li>
                <li>提供符合IETF PKIX規範的憑證驗證功能，可防禦「中間人攻擊(man-in-the-middle attack)」</li>
                <li>符合我國電子化政府之公鑰憑證安全檢查表的規定</li>
                </ul>
            </li>
            <li>憑證解析
                <ul>
                <li>提供X.509V3憑證格式欄位完整解析功能</li>
                </ul>
            </li>
            </ul>
        </div>
      
        <div class="section">
            <div class="title1">應用領域：</div>
            <ul class="gray_line">
            <li>網站登入之身分驗證(如電子化政府服務入口、投顧投信登入驗證、網路e櫃檯客戶登入、網站會員登入、差勤系統登入、企業入口網站登入等應用)</li>
            <li>電子簽章之應用(如電子公文線上簽核、網路下單等應用)</li>
            <li>數位信封之應用(如電子公文加密、薪資單加解密、S/MIME安全郵件加解密、個人隱私資料加解密等應用)</li>
            <li>憑證內容解析</li>
            </ul>
        </div>
      
        <div class="section">
            <div class="title1">產品規格：</div>
            <ul class="gray_line">
            <li>客戶端：
                <ul>
                <li>Windows平台及Internet explorer瀏覽器</li>
                </ul>
            </li>
            <li>伺服器端：
                <ul>
                <li>(Java版)標準Java web container，如Tomcat、Weblogic等</li>
                <li>(ASP版) IIS Server</li>
                </ul>
            </li>
            <li>支援卡片: 我國政府PKI各類IC卡、中華電信商用PKI IC卡 </li>
            </ul>
        </div>
      
        本公司免費提供全功能的「HiPKI網路認證套件包」試用版光碟，歡迎索取！<br />
        聯絡電話：03-424-5073<br />
        連絡信箱：<a href="mailto:eritta@cht.com.tw" target="_blank">eritta@cht.com.tw</a>
        
        <div align="right"><a href="javascript:history.go(-1);"><span class="btn_blue">返回</span></a></div>     
    </div>
};



export { product02 };