import React from 'react'

import ad1 from '../img/ad_170x85.jpg'
import ad2 from '../img/ad_170x85_02.jpg'

class AD extends React.Component {
    render() {
        return (
            <div class="ad"> 
                <a href="http://publicca.hinet.net/SSL-01.htm">
                    <img src = {ad1} />
                </a>
                <a href="http://www.tax.nat.gov.tw/index.html">
                    <img src = {ad2} />
                </a>
            </div>
        );
    }
}

export default AD


