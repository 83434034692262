
import React from 'react'
import { Link } from 'react-router-dom';

class Footer extends React.Component {
    render() {
        return (
            <div class="footer">
                中華電信數據通信分公司版權所有 © 2014 CHTD Internet Service by Chunghwa Telecom All Rights Reserved.<br />
                
                <Link to="/privacy">
                    隱私權政策<span class="blue">│</span> 
                </Link>
                
                <Link to="/copyright">
                    著作權聲明
                </Link>
            </div>
        );
    }
}

const copyright = () => {
    return <div>
        <div class="submenu"><strong>著作權聲明</strong></div>            
            <div class="gray_section" style="margin-bottom:30px;">本站刊載的所有內容，包括但不限於文字報導、照片、影像、圖片、影音檔案等素材，均受到中華民國著作權法及國際著作權法律的保障。</div>
            <p class="indent">網站內容的著作權為中華電信股份有限公司或其他授權本網站使用的內容提供者所有。</p>
            <p class="indent">本網站提供的內容與服務，主要針對用戶及信賴憑證者的使用，使用人利用時必須遵守著作權法的所有相關規定。使用者不得變更、發行、播送、轉賣、重製、改作、散佈、表演、展示或利用本網站局部或全部的內容或服務，以賺取利益。</p>        
    </div>
};

const privacy = () => {
    return <div><div class="submenu"><strong>金融憑證產品入口網站個人資料保護暨隱私權聲明</strong></div>
        
        <div class="gray_section" style = {{ marginBottom:'30px' }}>金融憑證產品入口網站（以下簡稱本網站）尊重並保護您個人資料的隱私，本網站僅依個人資料保護法第8條規定告知以下事項，請務必詳閱本網站之「個人資料保護暨隱私權聲明」</div>
            
        <div class="section">
            <div class="title1">壹、蒐集之目的（往來相關業務必要範圍）</div>
            <p class="indent">適用於您提供本網站之聯絡資訊如電話、傳真，或於網站客服信箱提出詢問或建議時，所涉及之個人公務聯絡資料蒐集、處理與利用行為。</p>
        </div>
        
        <div class="section">
            <div class="title1">貳、蒐集之個人資料類別<span class="gray_section" style = {{ padding:'5px', marginLeft:'10px' }}>代號：Ｃ○○一　識別類：辨識個人者</span></div>
            <p class="indent">一、當您與本網站洽辦業務，我們將視業務性質請您提供必要的個人公務聯絡資料，並在該特定目的範圍內處理及利用您的個人公務聯絡資料；非經您書面同意（包含但不限於紙本、公文、電子文件、電子郵件、電子簽章郵件等等），本網站不會將您的個人公務聯絡資料用於其他用途。</p>
        <p class="indent">二、如果您使用電話、傳真或電子郵件與本網站聯絡時，請您提供正確的公務電話、公務傳真號碼或公務電子郵件信箱地址，僅作為回覆詢問事項之用。</p>
        <p class="indent">三、您的個人公務聯絡資料在處理過程中，本網站將遵照相關之作業流程及規範處理，並依據資訊安全之要求，進行必要之控管。</p>
        <p class="indent">四、單純在本網站的瀏覽及檔案下載行為，本網站並不會蒐集任何有關個人的身分資料。</p>
        </div>
        
        <div class="section">
            <div class="title1">參、個人資料利用之期間、地區、對象及方式</div>
            <p class="indent">一、期間：個人資料蒐集之特定目的存續期間/依相關法令規定（如：電子簽章法與個人資料保護法）或契約約定之保存年限/本網站執行業務所必須之保存期間。（以上期間以最長者為準）。</p>
        <p class="indent">二、地區：中華民國及與本網站有業務往來之客戶營業處所在地。</p>
        <p class="indent">三、對象：中華電信股份有限公司及依法有調查權之政府機關。</p>
        <p class="indent">四、方式：以自動化機器或其他非自動化之利用方式。</p>
        <p class="indent">五、本網站絕不會提供、交換、出租或出售任何您的個人公務聯絡資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。</p>
        <p class="indent">六、 前項但書之情形包括不限於：</p>
            <ol style = {{ listStyle:'none' }}>
            <li>(一) 配合司法單位合法的調查。</li>
            <li>(二) 配合主管機關依職權或職務需要之調查或使用。</li>
            <li>(三) 基於善意相信揭露您的個人資料為法律所必需。</li>
            <li>(四) 當您在本網站的行為，可能損害或妨礙本網站權益或導致任何人遭受損害，而本網站揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。</li>
            <li>(五) 公務機關或學術研究機構基於公共利益為統計或學術研究而有必要，經本網站管理團隊請管理階層與法務人員確認，且資料經過提供者處理後或蒐集者依其揭露方式無從識別特定之當事人。</li>
            <li>(六) 有利於當事人權益。</li>
            <li>(七) 經當事人書面同意。</li>
            </ol>
        </div>
        
        <div class="section">
            <div class="title1">肆、依據個人資料法第3條規定，您得透過書面或以電子郵件聯絡本網站保有您之個人公務聯絡資料行使下列權利</div>
            <p class="indent">一、查詢或請求閱覽您的個人公務聯絡資料，而中華電信股份有限公司依法得酌收必要成本費用。</p>
        <p class="indent">二、請求製給您的個人公務聯絡資料複製本，而中華電信股份有限公司依法得酌收必要成本費用。</p>
        <p class="indent">三、請求補充或更正您的個人公務聯絡資料，惟依法請您應適當釋明其原因及事實。</p>
        <p class="indent">四、請求停止蒐集、處理或利用您的個人公務聯絡資料。惟依法中華電信股份有限公司因執行業務所必須者，不在此限。</p>
        <p class="indent">五、請求刪除您的個人公務聯絡資料。</p>
        </div>
        
        <div class="section">
            <div class="title1"><strong>伍、若不提供個人公務聯絡資料所致權益之影響</strong></div>
            <p class="indent">本網站用戶得自由選擇是否提供相關個人公務聯絡資料，若拒絕提供相關個人公務聯絡資料，本網站將無法進行必要之諮詢服務服務。</p>
        </div>
        
        <div class="section">
            <div class="title1"><strong>陸、其他相關聲明事宜</strong></div>
            <p class="indent">為維護本網站安全和網站系統能夠持續運作，我們提供相關的安全保護措施，並接受中華電信數據通信分公司資安管理制度之ISO/IEC 27001:2005內外稽。</p>
            <p class="indent">與本網站連結之其他網站有其個別的隱私權保護政策，其資料蒐集、處理、利用措施不適用本網站隱私權保護政策，本網站不負任何連帶責任。</p>
        </div>
        
        <div class="section">
            <div class="title1"><strong>柒、隱私權聲明之修改</strong></div>
            <p class="indent">本隱私權聲明將適時依據法律、技術之發展及資安管理制度之調整而配合修訂，以落實保障您隱私權及網路安全。當本網站完成相關隱私權聲明之修改時，會儘速刊登於本網站並於網站首頁提醒您閱覽。</p>
        </div>
        
        <div class="section">
            <div class="title1"><strong>捌、諮詢服務</strong></div>
            <p class="indent">如對本網站之服務有任何疑問與建議，歡迎以E-mail告知。<br />
            聯絡信箱:<a href="mailto:caservice@cht.com.tw" target="_blank">caservice@cht.com.tw</a></p>
        </div>
        
        <div align="right"><a href="#top"><span class="btn_blue">回頂端</span></a></div>
    </div>
};

export { privacy, copyright }
export default Footer




