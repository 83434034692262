import React from 'react';

import sample from "../img/sample.jpg"
import sample2 from "../img/sample2.jpg"

const product09 = () => {
    return <div>
        <div class="submenu">快意簽A+ Sign</div>
           
		<div class="gray_section" style = {{ marginBottom:'30px', textAlign:'justify', textJustify:'distribute' }}>
		中華電信 A+ Sign 能協助客戶快速導入數位化文件簽署，加速作業流程，提升業務效率，同時也避免傳統紙本作業的困擾。透過標準化 Web API 介面輕鬆升級與取代傳統實體文件簽署流程，支援載具及自然人憑證完成前端簽署作業，輕鬆完成 PDF 電子文件簽署。中華電信 A+Sign 為客戶打造便捷且公正的第三方線上文件驗證服務，直接透過 Adobe Acrobat/Reader 驗證即時又方便，已成功結合壽險業線上投保等實績案例，是客戶無紙化文件簽署與創新金融服務的最佳解決方案。
		</div>

		<font style = {{ fontSize: '18px' }}><b>中華電信 A+ Sign 優勢</b></font>
		<ul class="gray_line">
		  <li><font style = {{ fontSize: '16px' }}>完整的客戶服務</font>
			<ul class="nodisc" style = {{ paddingLeft: '20px' }}>
			  <font color="#448aca">
			  中華電持續優化顧客服務品質，並啟動「123客服心守則」，用傾聽、關懷、體驗打造有溫度的客戶服務。
			  </font>
			</ul>
		  </li>
		  <li><font style = {{ fontSize: '16px' }}>Adobe AATL 認可信賴清單會員</font>
			<ul class="nodisc" style = {{ paddingLeft: '20px' }}>
			  <font color="#448aca">
			  Adobe 認可信賴清單，可讓全球數千萬使用者(全球市占率第一)的Adobe Reader 或Acrobat開啟已簽署文件時，可直接對文件數位簽章完成驗證。透過Adobe Reader可直接驗證，免額外安裝其他Reader。
			  </font>
			</ul>
		  </li>
		  <li><font style = {{ fontSize: '16px' }}>已投保完整的產品責任保險</font>
			<ul class="nodisc" style = {{ paddingLeft: '20px' }}>
			  <font color="#448aca">
			  快意簽 A+ Sign 已投保完整的產品責任保險，提供客戶最大的保障，包含產品完工責任險、契約責任險及雇主責任險，賠償額度高達五百萬美金。其中錯誤疏漏專業責任險賠償額度高達美金1000萬。
			  </font>
			</ul>
		  </li>
		</ul>
		
		<div class="section">
        <p class="gray_line"></p>
		</div>

		<div>
			<div style = {{ float:'left', width: '50%'}} >
				<font style = {{ fontSize: '18px' }}><b>PDF 簽章國際應用案例</b></font>
				<ul class="gray_line">
				<li>Financial Industry (金融業)
					<ul class="nodisc" style = {{ paddingLeft: '20px' }}>
					<font color="#448aca">
						&#10004; 線上開戶同意書<br/>
						&#10004; 企業客戶線上合約簽署<br/>
						&#10004; 信用卡繳費單<br/><br/>
					</font>	
					</ul>
				</li>
				<li>Insurance (保險業)
					<ul class="nodisc" style = {{ paddingLeft: '20px' }}>
					<font color="#448aca">
						&#10004; 一般一年期保單<br/>
						&#10004; 長效型保單(10年、20年)<br/>
						&#10004; 要保書簽署<br/><br/>
					</font>	
					</ul>
				</li>
				<li>Government agencies (政府機關)
					<ul class="nodisc" style = {{ paddingLeft: '20px' }}>
					<font color="#448aca">
						&#10004; 數位證書、電子公報<br/>
						&#10004; 電子公文<br/>
						&#10004; 電子公證文件<br/>
					</font>	
					</ul>
				</li>
                </ul>
			</div>	
			<div style = {{ float:'left', width: '50%' }}>
				<br/>
				<li style = {{ paddingTop: '12px' }}>Securities Industry (證券業)<br/>
					<font color="#448aca" style = {{ paddingLeft: '40px' }}>
						&#10004; 每月電子對帳單<br/>
					</font>	
					<font color="#448aca" style = {{ paddingLeft: '40px' }}>
						&#10004; 線上約定帳戶簽署<br/>
					</font>	
				</li>
				<br/>
				<li style = {{ paddingTop: '26px' }}>E-Commerce (電子商務)<br/>
					<font color="#448aca" style = {{ paddingLeft: '40px' }}>
						&#10004; 商品報價單<br/>
					</font>	
					<font color="#448aca" style = {{ paddingLeft: '40px' }}>
						&#10004; 供應商合約簽署<br/><br/>
					</font>	
				</li>
				<br/>
				<li style = {{ paddingTop: '1px' }}>Securties Investment Trust and Consulting Business (投信投顧業)<br/>
					<font color="#448aca" style = {{ paddingLeft: '40px' }}>
						&#10004; 每月電子對帳單<br/>
					</font>	
					<font color="#448aca" style = {{ paddingLeft: '40px' }}>
						&#10004; 全權委託投資契約書<br/>
					</font>	
				</li>
			</div>
		</div>
<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
		<div class="section">
        <p class="gray_line"></p>
		</div>
		
		<div>
		<font style = {{ fontSize: '18px' }}><b>A+ Sign 憑證產品優勢與植入 AATL 效益</b></font>
		<ul class="gray_line">
		  <li>Adobe 認可信賴清單(Adobe Approved Trust List, AATL)是一項計劃，可讓全球數千萬使用者(全球市占率第一)的Adobe Reader 或Acrobat開啟已簽署文件時，可直接對文件數位簽章完成驗證
		  </li>
		  <li>透過Adobe Reader可直接驗證，免額外安裝其他Reader
		  </li>
		  <li>因植入AATL與支援長效驗證(LTV)，既使簽章憑證過期，文件合法性仍可直接透過Adobe Reader/Acrobat驗證成功，不需上傳第三方網站驗證，並支援時戳證明，提供簽署時間戳記不可否認性
		  </li>
		</ul>
		
		</div>
      	<p class="gray_line"><img src = { sample2 }  width="100%"/></p>
		
		<div class="section">
        <p class="gray_line"></p>
		</div>
		
		<font style = {{ fontSize: '18px' }}><b>產品整合與應用情境</b></font>
		<ul>
		  <li>可整合於電子合約或電子保單逐漸取代紙本合約。
		  </li>
		  <li>符合電子簽章法的電子文件簽署與驗證服務，確保網路上傳輸的PDF電子文件內容完整性、不可否認性，並可確認文件簽署者等資訊。
		  </li>
		  <li>提供可信賴簽署文件、可結合時間戳記提供可信賴文件存證、驗證與舉證
		  </li>
		</ul>
		
      	<p class="gray_line"><img src = { sample } width="100%"/></p>
		
        <div class="section">
			<p>連絡電話：02-2344-4820#4015<br></br>
			產品客服信箱：A_SignService@cht.com.tw<br></br>
			產品客服窗口：資分數安處企身科 黃柏舜先生</p>
		</div>
		

		
		<a href="/faca/Aplus_QA_V1.5_Signed.pdf" download="快意簽QA文件_V1.5_Signed.pdf">快意簽QA文件下載</a> 
    </div>
};

export { product09 };